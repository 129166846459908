import React from 'react'

import { getMetaOptions } from 'global-content/config'
import { reportError } from 'services/reportError'
import { reportMessage } from "services/reportMessage"
import { setupLazySentry } from 'utils/setupLazySentry'
import { SENTRY_READY } from 'utils/constants'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { updateGoogleAnalytics } from 'services/analytics'
import { reportBreadcrumb, SENTRY_PAGEVIEW_ERROR } from 'services/reportBreadcrumb'
import { Events } from 'design-system/services/events'
import { XHR_ABORT } from "utils/constants"

import styles from './AppError.css'

export const AppError = ({ error }) => {
  const iconSize = 110

  React.useEffect(() => {
    reportBreadcrumb({ message: window.location.href, category: SENTRY_PAGEVIEW_ERROR })

    if (error?.message?.includes(`Blocked by WAF`)) {
      const wafErrorMessage = `WAF error caused a Render App Error`
      updateGoogleAnalytics(`virtual_pageview_error_waf`)
      sendSentryEvent(() => {
        // Report Sentry message (non-error)
        reportMessage(
          wafErrorMessage,
          { extra: { message: error?.message } }
        )
      })
      Events.emitErrorPage({
        errorMessage: wafErrorMessage,
      })
    } else if (error.message.includes(XHR_ABORT)) {
      const abortErrorMessage = `${error.name} Failed to fetch resource due to network abort / cancelled.`
      updateGoogleAnalytics(`virtual_pageview_error_xhr_abort`)
      sendSentryEvent(() => {
        // Report Sentry message (non-error)
        reportMessage(
          abortErrorMessage,
          { extra: { message: error?.message } }
        )
      })
      Events.emitErrorPage({
        errorMessage: abortErrorMessage,
      })
    } else {
      updateGoogleAnalytics(`virtual_pageview_error`)
      sendDefaultSentryError(error)
      Events.emitErrorPage({
        errorMessage: error.message,
      })
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.appError}>
        <div className={styles.section}>
          <svg
            height={iconSize}
            viewBox="0 0 32 32"
            width={iconSize}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#848380">
              <path d="M16,32 C7.178,32 0,24.822 0,16 C0,7.178 7.178,0 16,0 C24.822,0 32,7.178 32,16 C32,24.822 24.823,32 16,32 Z M16,1 C7.72964286,1 1,7.72857143 1,16 C1,24.2714286 7.72857143,31 16,31 C24.2714286,31 31,24.2714286 31,16 C31,7.72857143 24.2714286,1 16,1 Z" />
              <path d="M13.942,21.783 C13.942,20.564 14.793,19.69 16.011,19.69 C17.229,19.69 18.034,20.564 18.058,21.783 C18.058,22.979 17.253,23.876 16.011,23.876 C14.77,23.876 13.942,22.979 13.942,21.783 Z M14.701,18.541 L14.172,8.124 L17.827,8.124 L17.321,18.541 L14.701,18.541 Z" />
            </g>
          </svg>

        </div>
        <div className={styles.heading}>
          Sorry, something went wrong.
        </div>
        <div className={styles.section}>
          Please check back soon.
        </div>
        <div>
          {`If this error persists, please contact us with this code `}
          <span>
            {window.$shoppingSessionId.value}
          </span>
        </div>
      </div>
    </div>
  )
}

async function sendDefaultSentryError(error) {
  const modifiedErr = new ErrorAdditional({
    title: `Render App Error - ${error.name}`,
    message: error.message,
    severity: 0,
    additional: error.additional,
    originalError: error,
    tags: {
      [SENTRY_TAGS.SOURCE_FILE]: `src/components/AppError/AppError.jsx`,
    },
  })

  sendSentryEvent(() => {
    reportError(modifiedErr)
  })
}

async function sendSentryEvent(callbackEvent) {
  if (window?.Sentry?.BrowserClient) {
    callbackEvent()
  } else {
    document.addEventListener(SENTRY_READY, () => {
      setTimeout(callbackEvent, 1000)
    })
    await setupLazySentry(getMetaOptions(`integrations.sentry.key`))
    window.Sentry.forceLoad()
  }
}
