import { XHR_ABORT, XHR_ERROR, XHR_TIMEOUT, XHR_NETWORK_ERROR } from "utils/constants"

export function xhrRequest(method, url, data = null, timeout = 10_000) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.timeout = timeout

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({
          status: xhr.status,
          response: xhr.responseText,
        })
      } else {
        reject({
          type: XHR_ERROR,
          status: xhr.status,
          message: `HTTP Error: ${xhr.status} for ${url}`,
        })
      }
    }

    xhr.ontimeout = () => {
      reject({
        type: XHR_TIMEOUT,
        message: `Request timed out for ${url}`,
      })
    }

    xhr.onerror = () => {
      reject({
        type: XHR_NETWORK_ERROR,
        message: `Network error occurred for ${url}`,
      })
    }

    xhr.onabort = () => {
      reject({
        type: XHR_ABORT,
        message: `Request was aborted for ${url}`,
      })
    }

    xhr.send(data)
  })
}
